import React, { useState } from 'react';
import AppState, { SCREENS } from '../../js/AppState';
import PeriodsPool from '../../js/PeriodsPool';
import Texts from '../../js/Texts';
import VertxConnection from '../../js/VertxConnection';
import Header from '../Header/Header';
import { AppTitle, Button } from '../Shared/Shared';
import './LoginScreen.css';



const LoginScreen = ({code}) => {
  const [codeInput, setCodeInput] = useState(code || "");
  const [pseudo, setPseudo] = useState(AppState.debugMode ? "debug" : "");

  const [error, setError] = useState(false);

  function handleSubmit(e) {
    e.preventDefault()

    setError(false)

    AppState.testCode(codeInput, pseudo)
    .catch(err => { setError(true) })

  }

  return (
    <div className="LoginScreen">
      {/* <Header /> */}
      <AppTitle />
      <form onSubmit={handleSubmit}>
        <h2>Login</h2>
        <p>
          <label htmlFor="meeting-code">{Texts.get("enter_group_code")}</label>
          <input id="meeting-code" autoFocus required value={codeInput} onChange={e => {setCodeInput(e.target.value); setError(false);}} />
        </p>

        <p>
          <label htmlFor="meeting-pseudo">{Texts.get("enter_team_name")}</label>
          <input id="meeting-pseudo" value={pseudo} required onChange={e => {setPseudo(e.target.value)}} />
        </p>

        <Button className='join-button'>Join</Button>

        <div className="error" style={{opacity: error ? 1 : 0}}>Connexion error ! Please verify your code</div>

        <div className="version">v{process.env.REACT_APP_VERSION}</div>

      </form>
    </div>
  );
};

export default LoginScreen;
