import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import AppState from "../../js/AppState";
import CardsPool from "../../js/CardsPool";
import PeriodsPool, { PERIOD_STATE } from "../../js/PeriodsPool";
import CardsList from "../CardsList/CardsList";
import Header from "../Header/Header";
import MeetingOverlay from "../MeetingOverlay/MeetingOverlay";
import ReleaseHeader from "../ReleaseHeader/ReleaseHeader";
import './Game.css';

const Game = () => {
  const showMeetingOverlay = (
  PeriodsPool.currentPeriod.state=== PERIOD_STATE.NOT_STARTED ||
  PeriodsPool.currentPeriod.state=== PERIOD_STATE.PAUSED)

  // debug
  useEffect(() => {
    if(PeriodsPool.currentPeriodIndex === 0 && AppState.debugMode) {
      let ids = ["1",  "5"]
      ids.forEach(id => {
        CardsPool.get(id).pick()
      })
    }
    if(PeriodsPool.currentPeriodIndex === 1 && AppState.debugMode) {

      let ids = ["3", "20", "21"]
      ids.forEach(id => {
        CardsPool.get(id).pick()
      })
    }
  }, []);

  return (

  <div className="Game">
    <Header>
      <ReleaseHeader />
    </Header>
    {/* <div className="shadowhide"></div> */}
    <CardsList cards={CardsPool.all} />

    {showMeetingOverlay && <MeetingOverlay state={PeriodsPool.currentPeriod.state} />}
  </div>)
}


  export default observer(Game)
