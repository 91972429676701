import React, { useState, useEffect } from 'react'
import Texts from '../../../js/Texts';

import './CardDetail.css'

const CardDetail = ({data}) => {

  let {value, id, color} = data

  let fills = []

  for(let i = 1; i<=value;i++) {
    fills.push(<div className={"CardDetail-fill num-" + i} key={i} style={{backgroundColor: color}} />)
  }
  return (
    <div className="CardDetail" >
      <div className="CardDetail-fills" style={{borderColor: color}}>
        {fills}
      </div>
      {/* <div className="tip">{name}</div> */}
      <div className="detail-name">{Texts.get("card-"+id)}</div>
    </div>
  );
};

export default CardDetail;
