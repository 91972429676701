import { observer } from "mobx-react-lite";
import React from "react";
import AppState, { SCREENS } from "../../js/AppState";
import PeriodsPool from "../../js/PeriodsPool";
import Texts from "../../js/Texts";
import CardsList from "../CardsList/CardsList";
import Header from "../Header/Header";
import { AppTitle, Button, IconButton } from "../Shared/Shared";
import {FaChevronRight} from 'react-icons/fa'
import "./Wrapup.css";
import { useEffect } from "react";
import Combinations from "../../js/Combinations";




const Wrapup = ({isScreen = true, period = PeriodsPool.currentPeriod}) => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  function next() {
    if(PeriodsPool.onLastPeriod) {
      AppState.setScreen(SCREENS.FINALWRAPUP)
    } else {
      PeriodsPool.next()
      AppState.setScreen(SCREENS.GAME)
    }
  }

  let cVcl = "period-value"
  cVcl += period.combinationValue > 0 ? " positive" : " negative"

  return(
    <div className="Wrapup-screen">
      {isScreen && <Header>
        <div className="line1">
          <AppTitle />
          <div className="btn-right">
            {!AppState.isMultiplayer && !PeriodsPool.onLastPeriod &&
            <>
              <span>{Texts.get('next-release-button')}</span>
              <IconButton onClick={next}><FaChevronRight /></IconButton>
            </>}

            {PeriodsPool.onLastPeriod &&
            <>
              <span>{Texts.get('final-wrapup-button')}</span>
              <IconButton onClick={next}><FaChevronRight /></IconButton>
            </>}
          </div>
        </div>
      </Header>}

      <div  className="Wrapup" key={period.id}>

        <div className="col1">
          <h2>{period.title}</h2>
          <div className="period-values">
            <div className="period-value">
              <span className="period-value-name">{Texts.get("WU-complexity-pts")}</span>
              <span className="period-value-val">{period.complexity}/{period.max_complexity}</span>
            </div>

            <div className="period-value">
              <span className="period-value-name">{Texts.get("WU-impact-pts")}</span>
              <span className="period-value-val">{period.impact}</span>
            </div>


            {period.combinationValue !== 0 &&
            <div className={cVcl}>
              {period.combinationValue > 0 &&<span className="period-value-name">{Texts.get("WU-bonus-pts")}</span>}
              {period.combinationValue < 0 &&<span className="period-value-name">{Texts.get("WU-discount-pts")}</span>}
              <span className="period-value-val">{period.combinationValue}</span>
            </div>}

          </div>
        </div>

        <div className="col2">
          <div className="impact-value">
            {period.totalImpact}
            <div className="impact-title">
              {Texts.get("WU-totalimpact-pts")}
            </div>
          </div>
        </div>
      </div>

      {period.boughtCards && <CardsList cards={period.boughtCards} showCombinations={true} />}
    </div>)


}

export default observer(Wrapup)
