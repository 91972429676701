import { setBasepath, useRoutes } from 'hookrouter';
import { observer } from "mobx-react-lite";
import React from "react";
import AppState, { SCREENS } from "../../js/AppState";
import FinalWrapup from "../FinalWrapup/FinalWrapup";
import Game from "../Game/Game";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoginScreen from "../LoginScreen/LoginScreen";
import Pdf from "../Pdf/Pdf";
import Popup from "../Popup/Popup";
import StartScreen from "../StartScreen/StartScreen";
import Wrapup from "../Wrapup/Wrapup";

import "./App.css";



const routes = {
  '/': () => <App />,
  '/:code': ({code}) => <App code={code} />,
}


setBasepath(window.CONFIG.basepath);


const AppCtnr = () => {
  const routeResult = useRoutes(routes);
  return routeResult
}




const App = observer(({code}) => {
  return (
    <div className="App">
      {AppState.screen === SCREENS.LOADING && <LoadingScreen />}
      {AppState.screen === SCREENS.LOGIN && <LoginScreen code={code} />}
      {AppState.screen === SCREENS.START && <StartScreen />}
      {AppState.screen === SCREENS.GAME && <Game />}
      {AppState.screen === SCREENS.WRAPUP && <Wrapup />}
      {AppState.screen === SCREENS.FINALWRAPUP && <FinalWrapup />}



      {AppState.showPdfPopup && <Popup close={() => {AppState.showPdfPopup = false}}><Pdf showStartBtn={false} /></Popup>}


      {AppState.debugMode && <div className="debugMode">Debug mode on</div>}
    </div>
  )
})

export default AppCtnr
